<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.endpoints.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.endpoints.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Endpoint', 'Inserir')"
      :sem-filtro="!buscarPermissao('Endpoint', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      :mostrar-seletor="buscarPermissao('Endpoint', 'Deletar')"
      filtros-com-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('Endpoint', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <botao-padrao
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </botao-padrao>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
//import CategoriaRelatorioService from '@common/services/cadastros/CategoriaRelatorioService';
import EndpointsService from '@common/services/cadastros/EndpointsService';
import Filtro from './filtro.vue';
import TabelaPadraoPrimeVue from '@components/tabelas/TabelaPadraoPrimeVue.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    TabelaPadraoPrimeVue,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.endpoints.tabela.nome'),
            sortable: true,
          },
          {
            value: 'url',
            text: this.$t('modulos.endpoints.tabela.url'),
            sortable: true,
            formatter: v => v || " - "
          },
          {
            value: 'moduloSistema',
            text: this.$t('modulos.endpoints.tabela.moduloSistema'),
            sortable: true,
          },
          {
            value: 'api',
            text: this.$t('modulos.endpoints.tabela.api'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.endpoints.titulos.listagem')
    );
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'RelatorioCategoria', 'Listar');
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros(paginaAtual = this.tabela.paginaAtual, porPagina = this.tabela.porPagina) {
      this.tabela.porPagina = porPagina;
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      EndpointsService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'endpoints-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'endpoints-editar',
        params: { id: item.id },
      });
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((item) => {
          promises.push(EndpointsService.excluir(item.id));
        });
        Promise.all(promises)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(this.$t(`modulos.endpoints.exclusao_sucesso`));
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
  },
};
</script>
